import { initLocale } from 'packs/i18n';

const translation = initLocale($("body").data('locale'))

// ================== HTML ============================
const createContainerSelectorOption = (item, index) => {
  let id = `check${index}`;
  return `
    <div class="container-popover-select-row">
      <span class="container-popover-select-span custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="${id}" ${item.checked ? 'checked' : ''}>
        <label class="custom-control-label" for="${id}">${item.text}</label>
      </span>

      <span class="container-popover-select-span-action ${item.checked ? '' : 'invisible'}">
        <button class="container-popover-minus" ${item.count <= 1 ? `disabled` : ''}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 11H11H7V13H11H13H17V11H13ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#9E9E9E"/>
          </svg>
        </button>
        <span class="count">${item.count}</span>
        <button class="container-popover-plus">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#9E9E9E"/>
          </svg>
        </button>
      </span>
    </div>
  `;
};

const createContainerElement = (containersList) => {
  return `
    <div id="container__wrapper">${containersList}</div>
    <div class="popover-done-button-wrapper">
      <button class="btn btn-link container__done">
        ${translation['done']}
      </button>
    </div>
  `;
}

// ==================== ACTIONS ============================
function handleDone(callback) {
  let activeState = containersState.filter(elem => elem.checked);
  let text;

  if (activeState.length == 0) {
    text = ''
  } else if (activeState.length == 1) {
    text = `${activeState[0].count} x ${activeState[0].text}`;
  } else {
    let sum = activeState.reduce(
      (acc, item) => acc + (item.checked ? item.count : 0),
      0,
    );
    text = `${sum} Containers`;
  }

  let value = activeState.map(elem => `${elem.text}:${elem.count}`);

  callback(text, value)
}

// ==================== STATE ============================
const containersState = []
let resetState = true;

function setContainersState(state) {
  if (resetState) {
    while (containersState.length) { containersState.pop(); }
    state.forEach((s) => containersState.push(s))
  }

  resetState = true
}

export function renderContainer({ signalContainersStateChanged, signalContainersDoneClicked, state }) {
  setContainersState(state)
  
  let containerSelectorOptions = containersState.map((item, index) => {return createContainerSelectorOption(item, index)}).join('');
  const containerElement = $(createContainerElement(containerSelectorOptions))

  // Checkbox event
  $.each(
    containerElement.find('.custom-control-input'),
    function (index) {
      $(this).on('change', function () {
        containersState[index].checked = !containersState[index].checked;
        resetState = false
        signalContainersStateChanged()
      });
    },
  );

  // Plus button events
  $.each(
    containerElement.find('.container-popover-plus'),
    function (index) {
      $(this).on('click', () => {
        containersState[index].count++;
        resetState = false
        signalContainersStateChanged()
      });
    },
  );

  // Minus button events
  $.each(
    containerElement.find('.container-popover-minus'),
    function (index) {
      $(this).on('click', () => {
        containersState[index].count--;
        resetState = false
        signalContainersStateChanged()
      });
    },
  );


  // onClick Done
  containerElement
    .find('button.container__done')
    .on('click', () => { handleDone(signalContainersDoneClicked) });

  return containerElement
}

export function forceTriggerContainerDone({signalContainersDoneClicked}){
  handleDone(signalContainersDoneClicked)
}