const SHIPPING_LINES_BL_PREFIXES = {
  'MSC': /^(EBKG)|(MEDUAF)/,
  'ARKAS': /^(ARKALY)/,
  'ZIM': /^(ZIMUALX)/,
  'CMA': /^(CFA)/,
  'ONE': /^(ALYD)/,
  'YANG MING': /^(YMEG)|(YMLUU)|(YMLUM)|(KIMXALY)|(YMLUR)/,
  'Hapag-Lloyd': /^(HLCUALY)/,
  'MEDKON': /^(MEDALYASH)/,
  'MARFRET': /^(BK)|(BL)/,
}

const SHIPPING_LINES_CONTAINER_PREFIXES = {
  'ADMIRAL': /^(ADMU)/,
  'ARKAS': /^(ARKU)|(ABHU)|(ASCU)/,
  'BMC': /^(BMSU)/,
  'CMA': /^(CGHU)|(CGMU)|(CGTU)|(CMAU)|(ECMU)|(MMCU)|(OTAU)|(STMU)|(CMDU)|(CMUU)/,
  'COSCO': /^(CBHU)|(CCLU)|(CSLU)|(CSNU)/,
  'EVERGREEN': /^(EGHU)|(EGSU)|(EISU)|(EMCU)|(HMCU)|(UGMU)/,
  'Hapag-Lloyd': /^(AZLU)|(CACU)|(CADU)|(CPSU)|(CSQU)|(FANU)|(HLBU)|(HLCU)|(HLXU)|(ITAU)|(IVLU)|(LYXU)|(LYTU)|(PCRU)|(QIBU)|(QNNU)|(SIUU)|(TLEU)|(TMMU)|(UACU)|(UAEU)/,
  'HYUNDAI': /^(HLXU)|(HDMU)|(HMCU)|(HMSU)/,
  'MAERSK': /^(APMU)|(BSLU)|(FAAU)|(FRLU)|(HDLU)|(KNLU)|(LOTU)|(MAEU)|(MALU)|(MCAU)|(MCHU)|(MCRU)|(MCSU)|(MGBU)|(MHHU)|(MIEU)|(MMAU)|(MNBU)|(MRKU)|(MRSU)|(MSAU)|(MSFU)|(MSKU)|(MSWU)|(MVIU)|(MWCU)|(MWMU)|(MWSU)|(NDLU)|(OCLU)|(POCU)|(PONU)|(SCMU)|(SEAU)|(TORU)|(CADU)|(GRIU)|(HASU)|(KHJU)|(KHLU)|(KRJU)|(SUDU)/,
  'MARFRET': /^(MFTU)/,
  'MARINA': /^(MCTU)|(MERU)|(MEXU)/,
  'MSC': /^(MEDU)|(MSCU)|(MSPU)|(MSMU)|(MSCZ)/,
  'ONE': /^(AKLU)|(ESSU)|(FDCU)|(KKFU)|(KKLU)|(KKTU)|(KLFU)|(KLTU)|(MOAU)|(MOEU)|(MOFU)|(MOGU)|(MOLU)|(MORU)|(MOSU)|(MOTU)|(NYKU)|(NYNU)|(ONEU)/,
  'OOCL': /^(OOLU)/,
  'PIL': /^(PCIU)|(PILU)/,
  'TARROS': /^(GETU)/,
  'WAN HAI': /^(WHMU)|(WHLU)|(WHSU)/,
  'YANG MING': /^(YMLU)|(YMMU)|(YMRU)|(YMSU)|(YMCU)/,
  'ZIM': /^(JXLU)|(ZCLU)|(ZIMU)|(ZCSU)/
}

export const match_shipping_line_prefix = (value) => {
  for (let [line, regex] of Object.entries(SHIPPING_LINES_BL_PREFIXES)) if(value.match(regex)?.length) return [line, 'booking_number'];
  for (let [line, regex] of Object.entries(SHIPPING_LINES_CONTAINER_PREFIXES)) if(value.match(regex)?.length) return [line, 'bl'];
  return null;
};
