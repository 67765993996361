import "../../jquery-ui";
import { match_shipping_line_prefix } from "./prefix";
import { validate_booking_bl } from "./validation";
import { debounce } from "lodash";

export function createAutoCompleteInput({shipping_lines}) {
  // ============================================ // ============================================

  // ============================================
  // ================= UTILS ====================
  const handleAutocomplete = (source) => {
    return (request, response) => {
      let term = request.term.toLowerCase();
      let exactMatching = []
      let nearMatching = []
      let restNotMatching = []

      source.forEach(function (elem) {
        elem.toLowerCase().trim() == term
          ? exactMatching.push(elem)
          : elem.toLowerCase().includes(term) 
            ? nearMatching.push(elem)
            : restNotMatching.push(elem)
      });

      response(exactMatching.concat(nearMatching).concat(restNotMatching));
    };
  };

  const validateInput = (mainSelector, inputSelector, source) => {
    let typedValue = $(inputSelector).val();
    let fieldSelector = `${mainSelector} .component-search__validation-error`;
    if (!typedValue || typedValue == '') $(fieldSelector).css('display', 'none');
    else {
      handleAutocomplete(source)({ term: typedValue }, result => {
        // Show validation error
        if (!result.length) showError(fieldSelector, `${typedValue} is not a valid shipping line`);
        else if (typedValue.toLowerCase() !== result[0].toLowerCase()) showError(fieldSelector, `${typedValue} is not a valid shipping line`);
        else $(fieldSelector).css('display', 'none');
      });
    }
  }

  const autocompleteSelectorOption = item => {
    return `
      <li class='notranslate' translate='no'>
        <div class="search-select-item">
          <span class="search-select-item-label">
            <span class="truncate">${item.label}</span>
          </span>
        </div>
      </li>
    `;
  };

  const selectItem = (selectors, source, item) => {
    const { mainSelector, inputSelector } = selectors;
    $(inputSelector).val(item.label || item);
    validateInput(mainSelector, inputSelector, source);
  }

  const createFlexibleInputComponent = (selectors, source, setSelect, data = undefined) => {
    const { mainSelector, inputSelector: selector } = selectors
    if (!$(selector).length) return;

    let searchAutocomplete = $(selector).autocomplete({
      classes: { [`ui-autocomplete`]: `autocomplete-country-select-list` },
      minLength: 0,
      delay: 200,
      source: handleAutocomplete(source),
      select: function (event, ui) {
        setSelect(selectors, source, ui.item)
        return false;
      },
      close: function (event, ui) { validateInput(mainSelector, selector, source) },
      change: function (event, ui) { validateInput(mainSelector, selector, source) },
      open: function () {
        let { x: left } = $(this).closest('.component-search__input-wrapper').get(0).getBoundingClientRect();
        $('.autocomplete-country-select-list:visible').css({ top: '+=18', left });
      },
    });

    searchAutocomplete.on('focus', function () {
      $(this).autocomplete("search");
    })

    searchAutocomplete.autocomplete('instance')._renderItem = function (ul, item) {
      return $(autocompleteSelectorOption(item)).appendTo(ul);
    };

    if (data) handleAutocomplete(source)({ term: data }, result => result.length && setSelect(selectors, source, result[0]))

    $("#number-field").on('input', function(){
      const matchedLine = match_shipping_line_prefix($(this).val());
      if (matchedLine) handleAutocomplete(source)({ term: matchedLine[0] }, result => {
        if(result.length) {
          setSelect(selectors, source, result[0])
          $(`#${matchedLine[1]}`).prop('checked', true)
        }
      })
    })

    $('#number-field').on('keyup', debounce(() => document.getElementById('number-field').dispatchEvent(new Event('finished_typing')), 500));
  }

  const validateNumberInput = async function (mainSelector, inputSelector) {
    const value = $(inputSelector).val()
    const shippingLine = $(`${mainSelector} .line-select`).val();
    const isBlSelected = $("#bl").is(":checked");
    const fieldSelector = `${mainSelector} .track-number-selector .component-search__validation-error`;

    const { isValid, message } = validate_booking_bl(value, shippingLine, isBlSelected)
    if (isValid) $(fieldSelector).css('display', 'none')
    else showError(fieldSelector, message)

    return { value, shippingLine, isBlSelected }
  }

  const showError = (selector, message) => {
    $(selector).css('display', 'block').text(message);
  }
  // ============================================

  // ============================================ // ============================================

  // ============================================
  // ============== CREATION ====================
  const createComponent = (mainSelector, shipping_lines) => {
    // Single Input
    createFlexibleInputComponent({
      mainSelector: `${mainSelector} .track-line-selector`,
      inputSelector: `${mainSelector} .line-select`,
    }, shipping_lines, selectItem)
  }


  createComponent('.component-track', shipping_lines)
  // ============================================

  $('#track form').on('keyup keypress', function(e) {
    if (e.which === 13) return false;
  });
  
  $('#track form').on('submit', function (e) {
    const { value, shippingLine, isBlSelected } = validateNumberInput('.component-track', '.component-track .number');
    const validationErrors = $(this).find('.component-search__validation-error');
    const shouldSubmit = $.grep(validationErrors, (ve) => $(ve).css('display') != 'none').length === 0;

    !shouldSubmit && process.env.NODE_ENV === 'production' && mixpanel.track('Invalid tracking', {
      'value': value,
      'sl': shippingLine,
      'selector': isBlSelected ? 'bl' : 'booking'
    })

    return shouldSubmit;
  });  
}
