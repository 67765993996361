import "../../jquery-ui"

export async function createCompanySelector() {
  // =======================================================
  // Company Selector
  // =======================================================

  const handleAutocomplete = (source) => {
    return (request, response) => {
      let term = request.term.toLowerCase();
      let exactMatching = []
      let nearMatching = []
  
      source.forEach(function (elem) {
        elem.name.toLowerCase().trim() == term
          ? exactMatching.push(elem)
          : elem.name.toLowerCase().includes(term) && nearMatching.push(elem)
      });
  
      response(exactMatching.concat(nearMatching));
    };
  };

  const validateInput = (element, source) => {
    let typedValue = element.val();
    if(typedValue && typedValue !== '') {
      handleAutocomplete(source)({ term: typedValue }, result => {
        if (!result.length || (!result.some(item => item.name === typedValue))) element.val(null);
      });
    }
  }

  // function impersonateCompany(companyId){
  //   if(!companyId) return;
  //   const params = Object.fromEntries(new URLSearchParams(window.location.search).entries())
  //   params['as'] = companyId
  //   window.location.search = new URLSearchParams(params).toString();
  // }  

  function autocompleteSelectorOption(item) {
    return `
      <li class='notranslate' translate='no'>
        <div class="search-select-item">
          <span class="search-select-item-label">
            <span class="truncate">${item.name}</span>
          </span>
        </div>
      </li>
    `;
  };

  function createComponent({companies}) {
    const inputElement = $(".company-select")
    const valueElement = $(".company-select-hidden")
    
    const companySearch = inputElement.autocomplete({
      classes: { [`ui-autocomplete`]: `autocomplete-country-select-list` },
      minLength: 0,
      source: handleAutocomplete(companies),
      select: function (event, ui) {
        inputElement.val(ui.item.name)
        valueElement.val(ui.item.id)
        validateInput(inputElement, companies);
        // impersonateCompany(ui.item.id)
        return false;
      },
      close: function (event, ui) { validateInput(inputElement, companies) },
      change: function (event, ui) { validateInput(inputElement, companies) },
      minLength: 3,
      delay: 500
    });

    companySearch.on('focus', function () {
      $(this).autocomplete("search");
    })

    companySearch.autocomplete('instance')._renderItem = function (ul, item) {
      return $(autocompleteSelectorOption(item)).appendTo(ul);
    };


    const companyId = gon.as
    if(companyId) {
      const company = companies.find((c) => c.id == companyId)
      inputElement.val(company.name)
      valueElement.val(company.id)
    }
  }

  return $.ajax({
    accepts: {
      mycustomtype: 'application/json'
    },
    dataType: 'json',
    url: `/company_selector`,
    success: function (selectors) {
      createComponent(selectors)
    }
  })

  // =======================================================  
}
