const SHIPPING_LINES_PATTERNS = {
  'MAERSK': /^(?=.*\d)[a-zA-Z\d]{9}$/,
  'SEALAND': /^(?=.*\d)[a-zA-Z\d]{9}$/,
  'CMA': /^([a-zA-Z]{3}[\w-]{1,8}\d{3})$/,
  'MSC': /^([a-zA-Z]{3}[\w-]{1,8}\d{3})|\d{6,10}$/,
  'Hapag-Lloyd': /^([a-zA-Z]{3}[\w-]{1,8}\d{6})|\d{8}$/,
  'COSCO': /^([a-zA-Z]{3}[\w-]{1,8}\d{3})|\d{8,10}$/,
  'OOCL': /^(?:[oO][oO][lL][uU])?\d{10}$/,
  'APL': /^([a-zA-Z]{3}[\w-]{1,8}\d{3})$/,
  'ARKAS': /^[aA][rR][kK][a-zA-Z]{3}\d{10}$/,
  'ONE': /^(ONEY\s*)?([a-zA-Z]{4}\s*\d{8})|([a-zA-Z]{5}\d{7})$/,
}

export const validate_booking_bl = (value, shippingLine, isBlSelected) => {
  let isValid = false;
  let message = '';

  if (!value) return { isValid, message: `Please specify the ${isBlSelected ? "Container Number" : "Booking number or BL"}`}
  value = value.trim()
  
  const hasPattern = SHIPPING_LINES_PATTERNS[shippingLine];
  const containerPattern = new RegExp(/^[a-zA-Z]{4}\d{6,7}$/);
  const bookingPattern = hasPattern && new RegExp(hasPattern);

  if (isBlSelected) {
    if (!value.match(containerPattern))
      return {isValid, message: 'Invalid Container Number ' + value};
  } else {
    if (hasPattern && !value.match(bookingPattern))
      return {isValid, message: 'Invalid Booking number or BL ' + value};
    else if (!hasPattern && value.length < 5)
      return {isValid, message: 'Invalid Booking number or BL ' + value};
  }

  return {isValid: true, message};
};