import 'bootstrap';
import 'select2';

import { container_icon, bulk_icon } from 'packs/components/compare/icons';
import { renderContainer, forceTriggerContainerDone } from './container';
import { renderBulk, forceTriggerBulkDone } from './bulk';

export function createPopoverComponent({ containers, bulk_commodities }) {

  // ================================ HTML ===============================
  const cargoTypeSelector = () => {
    return `
      <div class="component-search__cargo-types-tabs">
        <ul>
          <li class="${isBulkActive ? "" : "active"}" id="containers-item">
            <a role="tab" data-toggle="tab" href="#containers">
              ${container_icon}
              <span class="notranslate" translate="no">
                <span class="d-none d-md-block">Containers</span>
                <span class="d-none d-sl-block">Containers</span>
              </span>
            </a>
          </li>

          <li class="${isBulkActive ? "active" : ""}" id="bulk-item">
            <a role="tab" data-toggle="tab" href="#bulk">
              ${bulk_icon}
              <span class="notranslate" translate="no">
                <span class="d-none d-md-block">Bulk</span>
                <span class="d-none d-sl-block">Bulk</span>
              </span>
            </a>
          </li>
        </ul>
      </div>
    `;
  };

  const popoverUI = (containerInitializer, bulkInitializer) => {
    if(!window.bulk_loaded && !window.container_loaded) return

    if(!window.bulk_loaded) return renderContainer(containerInitializer);
    if(!window.container_loaded) return renderBulk(bulkInitializer);

    let cargoType = $(cargoTypeSelector());

    let containerBody = $(`
      <div class="tab-pane component-search__cargo-types-tabs-pane ${isBulkActive ? "" : "active"}" id="containers">
      </div>
    `).html(renderContainer(containerInitializer));
    let bulkBody = $(`
      <div class="tab-pane component-search__cargo-types-tabs-pane ${isBulkActive ? "active" : ""}" id="bulk">
      </div>
    `).html(renderBulk(bulkInitializer));
    let cargoBody = $(`
      <div class="tab-content component-search__cargo-types-tabs-content">
      </div>
    `).append([containerBody, bulkBody]);

    return [cargoType, cargoBody];
  };

  // =============================== STATE ===============================

  let isBulkActive = window.bulk_loaded && !window.container_loaded;

  // ============== CREATION ====================

  function createPopoverContainer({ selector }) {
    const selectPopover = $(`
      <div class="popover-body-wrapper">
      </div>
    `);

    const signalContainersStateChanged = () => {
      popoverRender()
    }

    const signalContainersDoneClicked = (text, value) => {
      $(selector).val(text)
      $(selector).popover('hide');
      $(`${selector}-hidden`).val(JSON.stringify(value));
      $(".cargo-icon").css('display', value.length ? 'none' : 'block');
      $(".cargo-icon-dark").css('display', value.length ? 'block' : 'none');
      $(".bulk-icon-dark").css('display', 'none');
    }

    const signalBulkStateChanged = () => {
      popoverRender()
    }

    const signalBulkDoneClicked = (text, value) => {
      $(selector).val(text);
      $(selector).popover('hide');

      $(`${selector}-hidden`).val(value);
      $(".cargo-icon").css('display', 'none');
      $(".cargo-icon-dark").css('display', 'none');
      $(".bulk-icon-dark").css('display', 'block');
    }

    const bulkInitializer = {
      signalBulkStateChanged, 
      signalBulkDoneClicked,
      bulk_commodities,
      state: {
        weight: 0,
        commodity: null
      }
    }

    const containerInitializer = {
      signalContainersStateChanged, 
      signalContainersDoneClicked,
      state: containers.map(container => {
        return {
          text: container,
          count: 1,
          checked: false,
        };
      })
    }

    const data = gon.search_params && gon.search_params['containers'];
    if (data && gon.is_bulk) {
      isBulkActive = true
      let [weight, commodity] = data.split(";");
      bulkInitializer.state = { weight, commodity }
    } else if (data) {
      isBulkActive = false
      const dataObj = JSON.parse(data).reduce((acc, elem) => {
        let splitted = elem.split(':');
        return {
          [splitted[0]]: splitted[1],
          ...acc
        }
      }, {})

      for (let state of containerInitializer.state) {
        if (dataObj[state.text]) {
          state.count = parseInt(dataObj[state.text]);
          state.checked = true;
        }
      }
    }

    function popoverRender() {
      let ui = popoverUI(containerInitializer, bulkInitializer);
      selectPopover.html(ui)

      // Cargo type tab switching event
      selectPopover
        .find('.component-search__cargo-types-tabs li')
        .on('click', function () {
          $('.component-search__cargo-types-tabs li').removeClass('active');
          $('.component-search__cargo-types-tabs li a').removeClass('active');
          $(this).addClass('active');
          isBulkActive = $(this).attr('id').includes('bulk')
        });
    }

    popoverRender();
    if (data) isBulkActive ? forceTriggerBulkDone(bulkInitializer) : forceTriggerContainerDone(containerInitializer)

    $(selector).popover({
      trigger: 'manual',
      content: selectPopover.get(0),
      placement: 'bottom',
      fallbackPlacement: ['bottom'],
      flip: 'bottom',
      html: true,
    }).on('click', function (e) {
      e.preventDefault();

      $(this).popover("show");

      $(".popover").css('margin-top', '20px')
      $(".popover").css('margin-left', '8px')
      $(".popover .arrow").css('display', "none")
    }).on('show.bs.popover', function () {
      $('html').on('mousedown', function (e) {
        var l = $(e.target);
        if ($('.popover-body').get(0) && l.get(0)) {
          let isInside = $.contains($('.popover-body').get(0), l.get(0));
          if (!isInside) {
            isBulkActive ? forceTriggerBulkDone(bulkInitializer) : forceTriggerContainerDone(containerInitializer)
          }
        }
      });
    }).on('hide.bs.popover', function () {
      $('html').off('mousedown')
    });
  }

  createPopoverContainer({ selector: '.cargo-select' });

  $('.cargo-arrow').on('click', function () {
    $('.cargo-select').trigger('click');
  })

  $('.cargo-icon').on('click', function () {
    $('.cargo-select').trigger('click');
  })
  // ============================================  
}