import "../../jquery-ui";
import { initLocale } from "../../i18n";

export function createAutoCompleteInput({locations}) {
  // ============================================ // ============================================
  // ============================================
  // =============== CONSTANTS ==================
  const LABEL_GEN = (origin = true, port = true) => { return `${origin && port ? translation["origin_port"] : origin && !port ? translation["origin_area"] : !origin && port ? translation["destination_port"] :  translation["destination_area"]}` }
  const NAME_GEN = (origin = true, port = true) => { return `${origin ? 'origin' : 'destination'}_${port ? 'port' : 'area'}` }
  // ============================================

  // ============================================
  // ================= UTILS ====================
  const isMultiInput = (mainSelector) => {
    return ($(`${mainSelector} .merged-input.multi-input`)).length
  }

  const handleAutocomplete = (mainSelector, source, searchValues=false) => {
    return (request, response) => {
      let term = request.term.toLowerCase();
      let exactMatching = [];
      let cities = [];
      let countries = [];

      source.exec(source.name, mainSelector).forEach(function (elem) {
        // Exact Matching: Port Value in EN and AR
        elem.value_ar?.replace(/[أإ]/g, 'ا').toLowerCase().trim() == term?.replace(/[أإ]/g, 'ا')
          ? exactMatching.push(elem)
          : elem.value.toLowerCase().trim() == term
            ? exactMatching.push(elem)
            // Cities Matching: Port INCLUDES Value in EN and AR
            : elem.value_ar?.replace(/[أإ]/g, 'ا').toLowerCase().includes(term?.replace(/[أإ]/g, 'ا'))
              ? cities.push(elem)
              : elem.value.toLowerCase().includes(term)
                ? cities.push(elem)
                // Country Matching: Country INCLUDES Country_Value in EN and AR
                : elem.country_ar?.replace(/[أإ]/g, 'ا').toLowerCase().includes(term?.replace(/[أإ]/g, 'ا'))
                  ? countries.push(elem)
                  : elem.country.toLowerCase().includes(term)
                    ? countries.push(elem)
                    // Check ISO Code
                    : elem.code != null && elem.code.toLowerCase().includes(term)
                      ? countries.push(elem)
                      // When initializing with data, they are in english so we need to search values not display names
                      : searchValues && elem.value.toLowerCase().trim() == term && exactMatching.push(elem) 
      });

      response(exactMatching.concat(cities).concat(countries).slice(0,25));
    };
  };

  const validateInput = (mainSelector, inputSelector, source) => {
    let typedValue = $(inputSelector).val();
    let fieldSelector = `${mainSelector} .component-search__validation-error`;
    if (!typedValue || typedValue == '') $(fieldSelector).css('display', 'none');
    else handleAutocomplete(mainSelector, source)({term: typedValue}, result => showError(fieldSelector, typedValue, result));
  }

  const showError = (fieldSelector, typedValue, result=[]) => {
    if (!result.length) $(fieldSelector).css('display', 'block').text(typedValue + ' is not a valid location');
    else if (typedValue !== result[0].display_name) $(fieldSelector).css('display', 'block').text(typedValue + ' is not a valid location');
    else $(fieldSelector).css('display', 'none');
  }

  const autocompleteSelectorOption = item => {
    return `
      <li class='notranslate' translate='no' data-port="${item.port}">
        <div class="search-select-tag"> 
          ${item.port ? "Ports" : "Areas"}
        </div>
        <div class="search-select-item">
          <span class="search-select-item-img">
            <img src="${item.imgUrl}" alt="${item.display_country}"/>
          </span>
          <span class="search-select-item-icon">
            <img src="${item.icon}" alt="${item.port ? 'port' : 'area'}"/>
          </span>
          <span class="search-select-item-label">
            <span class="truncate">${item.display_name}</span>
            <span class="truncate">${item.display_country}</span>
          </span>
        </div>
      </li>
    `;
  };

  const selectItem = (target = 'location') => {
    return (selectors, source, item) => {
      const { mainSelector, inputSelector, flagSelector } = selectors;

      setState(mainSelector, item, target);
      $(inputSelector).val(item.display_name);
      $(flagSelector).css('display', 'flex');
      $(`${flagSelector} flag`).attr('src', item.imgUrl);
      $(`${flagSelector} icon`).attr('src', item.iconUrl);
      $(`${mainSelector} .marker-wrapper`).css('display', 'none');

      syncState(mainSelector);
      validateInput(mainSelector, inputSelector, source);
    }
  }

  const invalidateState = (mainSelector, target) => {
    target === 'extra' ? state[mainSelector].extra = null : state[mainSelector].location = null
    syncState(mainSelector)
  }

  const toggleMultiInput = (mainSelector, toggle = true) => {
    $(`${mainSelector} .extra-selector`).css('display', toggle ? 'flex' : 'none');
    $(`${mainSelector} .flexible-input`).toggleClass('merged-input multi-input');
    $(`${mainSelector} .component-search__close-button`).css('display', toggle ? 'block' : 'none');
  }

  const createEvents = (mainSelector) => {
    $(`${mainSelector} .optional-port`).off('click').on('click', function () {
      $(this).css('display', 'none');
      toggleMultiInput(mainSelector);
    })

    $(`${mainSelector} .optional-area`).off('click').on('click', function () {
      $(this).css('display', 'none');
      toggleMultiInput(mainSelector)
      // Transfer state to extra
      state[mainSelector].extra = state[mainSelector].location
      state[mainSelector].location = null;
      syncState(mainSelector)
    })

    $(`${mainSelector} .location-selector .component-search__close-button`).off('click').on('click', function () {
      state[mainSelector].location = state[mainSelector].extra
      toggleMultiInput(mainSelector, false)
      invalidateState(mainSelector, 'extra')
    })

    $(`${mainSelector} .extra-selector .component-search__close-button`).off('click').on('click', function () {
      toggleMultiInput(mainSelector, false)
      invalidateState(mainSelector, 'extra')
    })

    // Cleared Input
    $(`${mainSelector} .location-selector input`).off('input').on('input', function() {
      if ($(this).val() === '') {
        state[mainSelector].location = state[mainSelector].extra
        if (isMultiInput(mainSelector)) toggleMultiInput(mainSelector, false)
        invalidateState(mainSelector, 'extra')
      }
    });

    $(`${mainSelector} .extra-selector input`).off('input').on('input', function () {
      if ($(this).val() === '') {
        if (isMultiInput(mainSelector)) toggleMultiInput(mainSelector, false)
        invalidateState(mainSelector, 'extra')
      }
    })
  }

  const createFlexibleInputComponent = (selectors, source, setSelect, data = undefined) => {
    const { mainSelector, inputSelector: selector } = selectors
    if (!$(selector).length) return;

    let searchAutocomplete = $(selector).autocomplete({
      classes: { [`ui-autocomplete`]: `autocomplete-country-select-list` },
      minLength: 0,
      delay: 200,
      source: handleAutocomplete(mainSelector, source),
      select: function (event, ui) {
        setSelect(selectors, source, ui.item)
        return false;
      },
      close: function (event, ui) { validateInput(mainSelector, selector, source) },
      change: function (event, ui) { validateInput(mainSelector, selector, source) },
      open: function () {
        let { x: left } = $(this).closest('.component-search__input-wrapper').get(0).getBoundingClientRect();
        $('.autocomplete-country-select-list:visible').css({ top: '+=18', left });
      },
      minLength: 2,
      delay: 500
    });

    searchAutocomplete.on('focus', function () {
      $(this).autocomplete("search");
    })

    searchAutocomplete.autocomplete('instance')._renderItem = function (ul, item) {
      return $(autocompleteSelectorOption(item)).appendTo(ul);
    };

    createEvents(mainSelector);
    if (data)
      handleAutocomplete(mainSelector, source, true)({ term: data }, result => {
        if(result.length) {
          setSelect(selectors, source, result[0])
          if(source.name === 'extra') toggleMultiInput(mainSelector);
        }
      })
  }

  const filterInputs = (source, name, selector) => {
    if (name === '' || selector === '') return source;

    const oppositeSelector = selector.includes('origin') ? selector.replace('origin', 'destination') : selector.replace('destination', 'origin')
    const oppositeCountry = state[oppositeSelector].location || state[oppositeSelector].extra || null;
    let tempSource = source;
    if(oppositeCountry) tempSource = source.filter((s) => s.port !== oppositeCountry.port || s.display_country !== oppositeCountry.display_country);
    if (state[selector].location && state[selector].extra) {
      if (name === 'location') return tempSource.filter((s) => !s.port && s.display_country === state[selector].extra.display_country) // Areas only of same country as EXTRA
      return tempSource.filter((s) => s.port && s.display_country === state[selector].location.display_country) // Ports only of same country as LOCATION
    } else if (state[selector].location) {
      if (name === 'location') return tempSource; // All locations
      return tempSource.filter((s) => s.port && s.display_country === state[selector].location.display_country); // Ports only of same country as LOCATION
    } else if (state[selector].extra) {
      if (name === 'location') return tempSource.filter((s) => !s.port && s.display_country === state[selector].extra.display_country) // Areas only of same country as EXTRA
      return tempSource; // All locations
    }
    return tempSource;
  }

  const portHasAreas = (source, port) => {
    return source.filter((s) => !s.port && s.display_country === port.display_country).length;
  }

  const createComponent = (mainSelector) => {
    const { data } = state[mainSelector]
    createFlexibleInputComponent({
      mainSelector: `${mainSelector}`,
      inputSelector: `${mainSelector} .location-search`,
      flagSelector: `${mainSelector} .location-selector .flag-wrapper`,
    }, selectInputs.locations, selectItem(), data?.location)

    createFlexibleInputComponent({
      mainSelector: `${mainSelector}`,
      inputSelector: `${mainSelector} .extra-search`,
      flagSelector: `${mainSelector} .extra-selector .flag-wrapper`,
    }, selectInputs.extra, selectItem("extra"), data?.extra)
  }
  // ============================================

  // ============================================ // ============================================

  // ============================================
  // ================= STATE ====================
  const state = {
    '.component-search-origin': {
      location: null,
      extra: null,
      data: {
        location: gon.search_params['origin_area'] && gon.search_params['origin_area'] !== '' ? gon.search_params['origin_area'] : gon.search_params['origin_port'],
        extra: gon.search_params['origin_area'] && gon.search_params['origin_area'] !== '' ? gon.search_params['origin_port'] : null
      }
    },
    '.component-search-destination': {
      location: null,
      extra: null,
      data: {
        location: gon.search_params['destination_area'] && gon.search_params['destination_area'] !== '' ? gon.search_params['destination_area'] : gon.search_params['destination_port'],
        extra: gon.search_params['destination_area'] && gon.search_params['destination_area'] !== '' ? gon.search_params['destination_port'] : null
      }
    }
  }

  const setState = (mainSelector, item, target) => state[mainSelector][target] = item
  const syncState = (mainSelector, doBreak = false) => {
    const isOrigin = mainSelector.includes('origin')
    const isLocation = state[mainSelector].location
    const isExtra = state[mainSelector].extra

    // Buttons
    $(`${mainSelector} .optional-port`).css('display', isLocation && !isLocation.port && !isExtra ? 'block' : 'none')
    $(`${mainSelector} .optional-area`).css('display', isLocation && isLocation.port && portHasAreas(selectInputs.locations.exec(), state[mainSelector].location) ? 'block' : 'none')

    // Inputs 
    $(`${mainSelector} .location-selector input[type='hidden']`).attr('name', NAME_GEN(isOrigin, isLocation && isLocation.port))
    $(`${mainSelector} .extra-selector input[type='hidden']`).attr('name', NAME_GEN(isOrigin, isExtra || (isLocation && !isLocation.port)))

    // Views
    $(`${mainSelector} .location-selector label`).html(LABEL_GEN(isOrigin, isLocation && isLocation.port))
    $(`${mainSelector} .extra-selector label`).html(LABEL_GEN(isOrigin, isExtra || (isLocation && !isLocation.port)))

    // Values
    $(`${mainSelector} .extra-selector input`).val(state[mainSelector].extra?.display_name)
    $(`${mainSelector} .extra-selector input[type='hidden']`).val(state[mainSelector].extra?.value)
    $(`${mainSelector} .extra-selector .flag-wrapper`).css('display', state[mainSelector].extra ? 'flex' : 'none')
    $(`${mainSelector} .extra-selector .marker-wrapper`).css('display', state[mainSelector].extra ? 'none' : 'inline-block')
    $(`${mainSelector} .extra-selector .flag-wrapper .flag`).attr('src', state[mainSelector].extra?.imgUrl)
    $(`${mainSelector} .extra-selector .flag-wrapper .icon`).attr('src', state[mainSelector].extra?.iconUrl)

    $(`${mainSelector} .location-selector input`).val(state[mainSelector].location?.display_name)
    $(`${mainSelector} .location-selector input[type='hidden']`).val(state[mainSelector].location?.value)
    $(`${mainSelector} .location-selector .flag-wrapper`).css('display', state[mainSelector].location ? 'flex' : 'none')
    $(`${mainSelector} .location-selector .marker-wrapper`).css('display', state[mainSelector].location ? 'none' : 'inline-block')
    $(`${mainSelector} .location-selector .flag-wrapper .flag`).attr('src', state[mainSelector].location?.imgUrl)
    $(`${mainSelector} .location-selector .flag-wrapper .icon`).attr('src', state[mainSelector].location?.iconUrl)

    // Inland Only
    const oppositeSelector = mainSelector.includes('origin') ? mainSelector.replace('origin', 'destination') : mainSelector.replace('destination', 'origin')
    const mainCountry = state[mainSelector].location?.display_country || state[mainSelector].extra?.display_country || null;
    const oppositeCountry = state[oppositeSelector].location?.display_country || state[oppositeSelector].extra?.display_country || null;
    if(mainCountry !== null && oppositeCountry !== null && mainCountry === oppositeCountry) {
      $(`${mainSelector} .optional-port`).css('display', 'none')
      $(`${mainSelector} .optional-area`).css('display', 'none')
      $(`${oppositeSelector} .optional-port`).css('display', 'none')
      $(`${oppositeSelector} .optional-area`).css('display', 'none')
    }

    if(!doBreak) {
      syncState(oppositeSelector, true)
    }
  }
  // ============================================

  // ============================================
  // ============== CREATION ====================
  const translation = initLocale($("body").data('locale'))
  const executionFn = (name = '', selector = '') => filterInputs(locations, name, selector);
  const selectInputs = {
    locations: {exec: executionFn, name: "location"},
    extra: { exec: executionFn, name: "extra" }
  }
  Object.keys(state).forEach((mainSelector) => createComponent(mainSelector));

  $('.flag-wrapper').on('click', function () {
    $(this).siblings("input").trigger('focus');
  })
  // ============================================

  $('#compare form').on('keyup keypress', function(e) {
    if (e.which === 13) return false;
  });
  
  $('#compare form').on('submit', function (e) {
    Object.keys(state).forEach((mainSelector) => {
      !state[mainSelector].location && showError(`${mainSelector} .component-search__validation-error`, $(`${mainSelector} .location-search`).val())
    })
    let validationErrors = $(this).find('.component-search__validation-error');
    return $.grep(validationErrors, (ve) => $(ve).css('display') != 'none').length === 0;
  });
}