import "../../jquery-ui";

export function createDatePicker() {
  // =======================================================
  // Ready On Datepicker
  // =======================================================
  let date = new Date();
  date.setDate(date.getDate() + 1);

  const initialValue = gon.search_params && gon.search_params['date'] || date;

  $('.readyon-datepicker').datepicker({
    minDate: date,
    maxDate: '+1M',
    dateFormat: 'dd/mm/yy',
    beforeShow: function (input, inst) {
      setTimeout(() => {
        let widget = $(inst).datepicker('widget');
        let { x: left } = $(this).closest('.component-search__input-wrapper').get(0).getBoundingClientRect();
        widget.css({ top: '+=18', left });
      })
    },
  })

  $('.readyon-datepicker').datepicker('setDate', initialValue);
  // =======================================================  
}
