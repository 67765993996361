import "../jquery-ui";

import * as lazyframe from 'lazyframe';
import "../components/home-search"

// =====================================================================
// ======================= Hero Section Scroll =========================
function heroAnimation() {
  let appearing = 0;
  const animationLength = $(".moving-title .moving").length;
  $(".moving-title .moving").each(function (index) {
    if ($(this).css("display") == "block") {
      $(this).css("display", "none")
      appearing = index
    }
  })

  $(".multiple-lines__concealer").removeClass('appear').addClass('hide')
  $($(".moving-title .moving")[(appearing + 1) % animationLength]).css("display", "block")

  setTimeout(() => {
    $(".multiple-lines__concealer").removeClass('hide').addClass('appear')
    setTimeout(heroAnimation, 6 * 1000)
  }, 2 * 1000)
}
setTimeout(heroAnimation, 6 * 1000)
// =====================================================================


// =====================================================================
// ============ Company Destination Deal Section Action ================

$(document).on('company-destination-deals-loaded', function(){
  $('.rolling-deals-section__element').on('click', function (event) {
    const url = $(this).data('url');
    if (url) {
      window.location.href = url;
      process.env.NODE_ENV === 'production' && mixpanel.track('Company Destination Good Deal', {
        'from': $(this).data('from'),
        'to': $(this).data('to'),
        'price': $(this).data('price')
      })
    }
  });  
})

// =====================================================================


// =====================================================================
// ===================== Bookmark Section Action =======================

$(document).on('bookmark-deals-loaded', function(){
  $(".bookmark-remove-icon").on('click', function(){
    const element = $(this)
    const bookmarkID = element.data('bookmark')
    $.ajax({
      method: "get",
      url: `/shipments/remove_bookmark?id=${bookmarkID}`,
      success: function (data) {
        element.parents('.bookmark-section__element').css('display', 'none')
      }
    })
  })
  
  $('.bookmark-section__element-wrapper').on('click', function (event) {
    const url = $(this).data('url');
    if (url) {
      window.location.href = url;
      process.env.NODE_ENV === 'production' && mixpanel.track('Bookmark Deal', {
        'from': $(this).data('from'),
        'to': $(this).data('to'),
        'price': $(this).data('price')
      })
    }
  });
})

// =====================================================================

// =====================================================================
// ===================== Good Deal Section Scroll ======================

$(document).on('good-deals-loaded', function(){
  $('.good-deal-section__arrows .arrow').on('click', function (event) {
    const amount = $('.good-deal-section__element').width() * 1.5 + 56;
    const scroll = $(this).data('direction') === 'left' ? `-=${amount}` : `+=${amount}`
    $('.good-deal-section__content').animate({ scrollLeft: scroll })
  });
  
  $('.good-deal-section__element').on('click', function (event) {
    const url = $(this).data('url');
    if (url) {
      window.location.href = url;
      process.env.NODE_ENV === 'production' && mixpanel.track('Good Deal', {
        'from': $(this).data('from'),
        'to': $(this).data('to'),
        'price': $(this).data('price')
      })
    }
})
});

// =====================================================================


// =====================================================================
// ===================== Values Section Animation ======================
$('.values-section__element').on({
  mouseenter: function () {
    $(this).toggleClass('values-section__element-grow');
    $(this).siblings().toggleClass('values-section__element-minimized');
  },
  mouseleave: function () {
    $(this).toggleClass('values-section__element-grow');
    $(this).siblings().toggleClass('values-section__element-minimized');
  }
})
// =====================================================================

// =====================================================================
// ===================== FAQs Section Accordion ========================
$(".faq-section__element").each(function (index) {
  const options = {}
  if (index !== 0) options['active'] = false
  $(this).accordion({
    collapsible: true,
    icons: null,
    ...options,
    beforeActivate: function (event, ui) {
      $(this).toggleClass('faq-section__element-open')
    },
  });
})

$(window).on('resize', function () {
  $(".faq-section__element").accordion("refresh");
});
// =====================================================================


// =====================================================================
// ========================== Video Section ============================

lazyframe($('#youtube-intro'), {
  debounce: 250,
  lazyload: true,
  autoplay: true,
})

$(".video-section__player-button").on('click', function () {
  $(".video-section__thumbnail").css('display', 'none');
  $(".video-section__frame").css('display', 'block');
})

// =====================================================================
