export function initLocale(locale) {
  return {
    en: {
      origin_port: "Origin Port",
      origin_area: "Origin Area",
      destination_port: "Destination Port",
      destination_area: "Destination Area",
      done: "Done"
    },
    ar: {
      origin_port: "ميناء المغادرة",
      origin_area: "نقطة المغادرة",
      destination_port: "ميناء الوصول",
      destination_area: "نقطة الوصول",
      done: "اختر"
    }
  }[locale]
}
